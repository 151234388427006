export default [
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        /* 0 */ label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        visible: true,
        key:"date_from"
    },
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        /* 1 */ placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        visible: true,
        key:"date_to"
    },
    {
        type: "select",
        label: "Department",
        margin: true,
        showLabel: true,
        options: [
            { value: null, label: "All" },
            { value: 7, label: "Boost Credit" },
            { value: 20, label: "Connection"},
            { value: 6, label: "Customer Service Regular" },
            { value: 22, label: "Customer Service Digital" },
            { value: 5, label: "Debt Solution" }
        ],
        /* 2 */ model: "",
        reduce: "value",
        selectText: "label",
        cols: 12,
        md: 2,
        visible: false,
        key: "program"
    },
    {
        type: "select",
        label: "Advisor",
        margin: true,
        showLabel: true,
        options: [],
        /* 3 */ model: "",
        reduce: "id",
        selectText: "name",
        cols: 12,
        md: 2,
        visible: false,
        key: "advisor"
    },
    {
        type: "select",
        label: "Status",
        margin: true,
        showLabel: true,
        options: [
            { value: null, label: "All" },
          /* 4 */ { value: 1, label: "Pending" },
            { value: 2, label: "Acepted" },
            { value: 3, label: "Rejected" },
        ],
        model: "",
        reduce: "value",
        selectText: "label",
        cols: 12,
        md: 2,
        visible: false,
        key:"status"
    },

    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        /* 5 */ label: "Status From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        visible: false,
        key:"status_from"
    },
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "Status To",
        /* 6 */ placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        visible: false,
        key:"status_to"
    },

    {
        type: "select",
        label: "Result",
        margin: true,
        showLabel: true,
        options: [
            { value: null, label: "All" },
          /* 7 */ { value: 1, label: "Pending" },
            { value: 2, label: "Approved" },
            { value: 3, label: "Denied" },
        ],
        model: "",
        reduce: "value",
        selectText: "label",
        cols: 12,
        md: 2,
        visible: true,
        key:"result"
    },
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        /* 8 */ label: "Result From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        visible: false,
        key:"result_from"
    },
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "Result To",
        /* 9 */ placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        visible: false,
        key:"result_to"
    },
    {
        type: "select",
        label: "Paid",
        margin: true,
        showLabel: true,
        options: [
            { value: null, label: "All" },
            { value: 1, label: "Pending" },
          /* 10 */ { value: 2, label: "Yes" },
            { value: 3, label: "No" },
        ],
        model: "",
        reduce: "value",
        selectText: "label",
        cols: 12,
        md: 2,
        visible: false,
        key:"paid"
    },
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "Paid From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        visible: false,
        key:"paid_from"
    },
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "Paid To",
        /* 12 */ placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        visible: false,
        key:"result_to"
    },
    {
        type: "select",
        label: "Specialist",
        margin: true,
        /* 13 */ showLabel: true,
        options: [],
        model: "",
        reduce: "id",
        selectText: "full_name",
        cols: 12,
        md: 2,
        visible: false,
        key: "specialists"
    },
    {
        key: "type",
        type: "select",
        margin: true,
        showLabel: true,
        label: "Type",
        model: null,
        options: [
            { id: null, text: "All" },
            { id: 156, text: "Mortgage loan" },
            { id: 157, text: "Auto loan" },
            { id: 158, text: "Personal loan" },
        ],
        reduce: "id",
        selectText: "text",
        visible: true
    },
]