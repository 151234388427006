export default [
    {
        key: "client",
        label: "Client",
        thClass: "",
        tdClass: "",
        visible: true
    },
    {
        key: "name_advisor",
        label: "Advisor",
        thClass: "",
        tdClass: "",
        visible: true
    },
    {
        key: "type_potential",
        label: "Type",
        class: "text-center px-1",
        visible: true,
      },
    {
        key: "name_specialist",
        label: "Specialist",
        thClass: "",
        tdClass: "",
        visible: true
    },
    {
        key: "responsible",
        label: "Responsible",
        thClass: "text-center",
        tdClass: "text-center",
        visible: false
    },
    {
        key: "status",
        label: "Status",
        thClass: "",
        tdClass: "",
        visible: false
    },
    {
        key: "form",
        label: "Form",
        thClass: "text-center",
        tdClass: "text-center",
        visible: true
    },
    {
        key: "status_result",
        label: "Result",
        thClass: "text-center",
        tdClass: "",
        visible: true
    },
    {
        key: "bank_info",
        label: "Bank Info",
        thClass: "text-center",
        tdClass: "text-center",
        visible: true
    },
    {
        key: 'minimum_amount',
        label: 'Minimum amount',
        class: 'text-center',
        visible: true,
      },
    {
        key: "status_paid",
        label: "Paid",
        thClass: "text-center",
        tdClass: "text-center",
        visible: true
    },
    {
        key: "amount",
        label: "Amount",
        thClass: "text-right pr-2",
        tdClass: "text-right pr-2",
        thStyle: {
            width: "8%"
        },
        visible: true
    },
    {
        key: "files",
        label: "Files",
        class: "text-center",
        visible: true
    },
    {
        key: "binnacle",
        label: "Binnacle",
        class: "text-center",
        visible: false
    },
    {
        key: "tracking",
        label: "Tracking",
        class: "text-center",
        visible: true
    },
    {
        key: "actions",
        label: "Actions",
        class: "text-center",
        visible: true
    },
]