<template>
  <b-modal
    v-model="visible"
    size="lg"
    @hidden="close"
    title="Files"
    title-tag="h3"
    modal-class="modal-primary"
    hide-footer
    centered
  >
    <b-container>
      <template v-if="edit">
        <b-row class="justify-content-end">
          <b-button
            @click="changeStateAddFile"
            :class="addFile ? 'bg-danger' : 'bg-success'"
            size="md"
            style="border: none; font-size: 15px"
          >
            <feather-icon
              :icon="addFile ? 'MinusIcon' : 'PlusIcon'"
              size="15"
            />
            {{ this.addFile ? "Hide" : "Add File" }}
          </b-button> </b-row
        ><br />
        <b-row v-if="addFile">
          <drag-and-drop
            ref="dyd"
            class="w-100 mb-1"
            v-model="files"
            :filesArray="files"
          />

          <b-col>
            <b-row class="justify-content-center">
              <b-button
                variant="warning"
                @click="uploadFiles"
                :disabled="files.length === 0"
              >
                <feather-icon icon="UploadCloudIcon" /> UPLOAD
              </b-button>
            </b-row>
          </b-col>
        </b-row>
        <br />
      </template>
      <b-row>
        <b-table :items="items" :fields="fields" ref="FileTable" show-empty>
          <template #cell(file_name)="data">
            <feather-icon :icon="fileIcon(data.item.file_type)" />
            <a :href="data.item.file_url" target="_blank" class="ml-1">{{
              data.value
            }}</a>
          </template>
          <template #cell(actions)="data">
            <center>
              <feather-icon
                v-if="edit"
                size="18"
                class="text-danger cursor-pointer"
                icon="Trash2Icon"
                @click="deleteFile(data.item.id)"
              />
            </center>
          </template>
        </b-table>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import PotencialAppointmentService from "@/views/commons/components/potencial-appointment/services/potencial-appointments.service";
import { mapState, mapGetters } from "vuex";
export default {
  name: "ModalUploadFiles",
  components: { DragAndDrop },
  props: {
    potencialAppId: { type: Number, required: true },
    program: { type: Number, required: true },
    edit: { type: Boolean, default: () => true },
  },
  data() {
    return {
      visible: false,
      addFile: false,
      items: [],
      files: [],
      hasFile: false,
    };
  },
  methods: {
    async start() {
      let data = await PotencialAppointmentService.getFiles({
        id: this.potencialAppId,
      });
      this.items = [...data];
    },
    async uploadFiles() {
      try {
        const formData = new FormData();
        this.files.forEach((file) => {
          formData.append("files[]", file, file.name);
        });
        formData.append("program_id", this.program);
        formData.append("id", this.potencialAppId);
        formData.append("id_user", this.currentUser.user_id);

        let confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          this.addPreloader();
          let result = await PotencialAppointmentService.uploadFiles(formData);

          if (result) {
            this.files = [];
            this.changeStateAddFile();
            this.hasFile = true;
            await this.start();
            this.removePreloader();
            this.showSuccessSwal();
          } else {
            this.removePreloader();
            this.showErrorSwal();
          }
        }
      } catch (ex) {
        this.removePreloader();
      }
    },
    async deleteFile(id) {
      let data = { idFile: id, idUser: this.currentUser.user_id };
      let confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        let result = await PotencialAppointmentService.deleteFile(data);
        this.removePreloader();

        if (result) {
          this.showSuccessSwal();
          this.hasFile = true;
          await this.start();
        } else {
          this.showErrorSwal();
        }
      }
    },

    close() {
      this.visible = false;

      this.$emit("close", this.hasFile);
    },
    changeStateAddFile() {
      this.addFile = !this.addFile;
    },
    fileIcon(doc) {
      switch (doc.toLowerCase()) {
        case "pdf":
          return "FileTextIcon";
          break;
        case "docx":
          return "FileTextIcon";
          break;
        case "xlsx":
          return "FileTextIcon";
          break;
        case "png":
          return "ImageIcon";
          break;
        case "jpg":
          return "ImageIcon";
          break;
        default:
          return "FileIcon";
          break;
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    fields() {
      return [
        { key: "file_name", label: "File Name", thClass: "text-left" },
        { key: "file_size", label: "Size", thClass: "text-left" },
        { key: "upload_by", label: "Upload By", thClass: "text-left" },
        { key: "actions", label: "Actions", thClass: "text-center" },
      ];
    },
  },
  async created() {
    try {
      this.addPreloader();
      await this.start();
      this.removePreloader();
      this.visible = true;
    } catch (ex) {
      this.removePreloader();
      this.close();
      console.log(ex);
    }
  },
};
</script>