var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('filter-slot',{staticClass:"border-3 border-table-radius p-0 m-0",attrs:{"filter":_vm.visibleFilters,"filter-principal":_vm.filterSlot.filterPrincipal,"total-rows":_vm.filterSlot.paginate.totalRows,"paginate":_vm.filterSlot.paginate,"start-page":_vm.filterSlot.paginate.startPage,"to-page":_vm.filterSlot.paginate.toPage},on:{"reload":function($event){return _vm.$refs['potencialAppointmentTable'].refresh()},"reset-all-filters":_vm.resetAllFilters},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"potencialAppointmentTable",staticClass:"m-0 p-0",attrs:{"items":_vm.myProvider,"fields":_vm.visibleFields,"current-page":_vm.filterSlot.paginate.currentPage,"per-page":_vm.filterSlot.paginate.perPage,"busy":_vm.filterSlot.isBusy,"sticky-header":"500px","show-empty":"","responsive":"","small":"","foot-clone":false},on:{"update:currentPage":function($event){return _vm.$set(_vm.filterSlot.paginate, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.filterSlot.paginate, "currentPage", $event)},"update:perPage":function($event){return _vm.$set(_vm.filterSlot.paginate, "perPage", $event)},"update:per-page":function($event){return _vm.$set(_vm.filterSlot.paginate, "perPage", $event)},"update:busy":function($event){return _vm.$set(_vm.filterSlot, "isBusy", $event)}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(client)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"5px"}},[_c('IconStatusAccount',{attrs:{"status":item.status_account_id,"fromBc":""}}),_c('router-link',{attrs:{"target":"_blank","to":{
                name: _vm.clientDashboardRouteName,
                params: {
                  idClient: ("" + (item.account_id)),
                },
              }}},[_vm._v(" "+_vm._s(item.client_name)+" ")])],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"d-block",staticStyle:{"margin-left":"18px"}},[_vm._v(" "+_vm._s(item.account)+" ")])]),(item.program_id === 3)?_c('b-badge',{staticClass:"font10 ml-1 text-uppercase",attrs:{"variant":item.client_type_id == 2 ? 'light-primary' : 'light-warning'}},[_vm._v(" "+_vm._s(item.client_type_id == 2 ? "Digital" : "Regular")+" ")]):_vm._e()]}},{key:"cell(name_specialist)",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(item.name_specialist))]),_c('span',[_vm._v(_vm._s(_vm._f("myGlobalWithHour")(item.created_at)))])])]}},{key:"cell(responsible)",fn:function(data){return [_c('center',[_c('b-badge',{class:_vm.statusColorBackground(data.value),attrs:{"id":"span_status","pill":""}},[(data.item.responsible == 1 && _vm.moduleId == 25)?_c('span',[_vm._v("IN AGENT")]):_c('span',[_vm._v(" "+_vm._s(data.item.responsible)+" ")])])],1)]}},{key:"cell(form)",fn:function(ref){
              var item = ref.item;
return [_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('Form Potential'),expression:"'Form Potential'",modifiers:{"hover":true,"left":true}}],staticClass:"cursor-pointer",class:[_vm.colorIconForm(item)],attrs:{"icon":"ClipboardIcon","size":"20"},on:{"click":function($event){return _vm.openFormModal(item)}}})]}},{key:"cell(status_result)",fn:function(ref){
              var item = ref.item;
return [(_vm.availableForm(item))?_c('ChangeStatusP',{attrs:{"item":item,"moduleId":_vm.moduleId,"options":_vm.table.optionsStatusResult,"inSpecialist":_vm.inSpecialist,"nameProp":"status_result","option":"StatusResult"},on:{"send":_vm.updateStatus,"refresh":_vm.refreshTable}}):_vm._e()]}},{key:"cell(bank_info)",fn:function(ref){
              var item = ref.item;
return [(_vm.availableForm(item))?_c('feather-icon',{class:_vm.statusColorBankInfo(item),attrs:{"icon":"CreditCardIcon","size":"20"},on:{"click":function($event){return _vm.openBankInfo(item)}}}):_vm._e()]}},{key:"cell(minimum_amount)",fn:function(ref){
              var item = ref.item;
return [_vm._v(" $ "+_vm._s(parseFloat(item.minimum_price).toFixed(2))+" ")]}},{key:"cell(status_paid)",fn:function(ref){
              var item = ref.item;
return [(item.status_paid === 'PENDING' && _vm.availableForm(item))?_c('div',{staticClass:"text-warning"},[_vm._v(" VERIFYING ")]):[(
                item.status_result == 'APPROVED' &&
                item.bank_info &&
                item.bank_info.status_done == 1 &&
                _vm.availableForm(item)
              )?_c('ChangeStatusP',{attrs:{"item":item,"moduleId":_vm.moduleId,"options":_vm.table.optionsStatusPaid,"inSpecialist":_vm.inSpecialist,"nameProp":"status_paid","option":"StatusPaid"},on:{"send":_vm.updateStatus}}):_vm._e()]]}},{key:"cell(amount)",fn:function(ref){
              var item = ref.item;
return [(item.amount != null && item.bank_info != null)?_c('div',{staticClass:"d-flex flex-column"},[_c('span',{attrs:{"title":"Fee"}},[_vm._v(_vm._s(_vm._f("formatMoney")(item.amount)))])]):_vm._e()]}},{key:"cell(files)",fn:function(ref){
              var item = ref.item;
return [_c('feather-icon',{staticClass:"cursor-pointer",class:Number(item.count_file) > 0 ? 'text-primary' : '',attrs:{"icon":"PaperclipIcon","size":"20"},on:{"click":function($event){return _vm.openModalFiles(item)}}})]}},{key:"cell(binnacle)",fn:function(ref){
              var item = ref.item;
return [_c('tabler-icon',{staticClass:"text-secondary",attrs:{"badge":_vm.totalBinnacle(item),"badge-classes":"badge-important","icon":"BrandMessengerIcon","size":"25"}})]}},{key:"cell(tracking)",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-content-around px-1"},[_c('feather-icon',{staticClass:"cursor-pointer text-info",attrs:{"icon":"ListIcon","size":"20"},on:{"click":function($event){return _vm.openModalTracking(item)}}})],1)]}},{key:"cell(actions)",fn:function(ref){
              var item = ref.item;
return [(
              _vm.inSpecialist &&
              item.status == 'PENDING' &&
              item.status_paid == null
            )?_c('feather-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"TrashIcon","size":"20"},on:{"click":function($event){return _vm.removeRegister(item)}}}):_vm._e()]}},(_vm.tabName == 'COMPLETED')?{key:"custom-foot",fn:function(){return [_c('b-tr',{staticClass:"bg-dark fixed-footer"},[_c('b-th',{attrs:{"colspan":_vm.indexAmount}}),_c('b-th',[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"font-size":"16px"}},[_c('b-badge',{staticClass:"rounded-pill mb-1 ml-auto",attrs:{"variant":"info"}},[_vm._v("Sub Total: $ "+_vm._s(_vm._f("currency")(_vm.subtotalAmount)))]),_c('b-badge',{staticClass:"rounded-pill ml-auto",attrs:{"variant":"info"}},[_vm._v("Total: $ "+_vm._s(_vm._f("currency")(_vm.totalAmount)))])],1)]),_c('b-th',{attrs:{"colspan":"11"}})],1)]},proxy:true}:null],null,true)}),_c('br')]},proxy:true}])}),(_vm.activeModalTracking)?_c('ModalTracking',{attrs:{"data":_vm.activeModalTracking},on:{"close":_vm.closeModalTracking}}):_vm._e(),(_vm.showAddCharge)?_c('AddCharge',{attrs:{"action":1,"data":_vm.clientData,"allPaymentMethods":false,"sendFrom":"potencial_appointments"},on:{"close":_vm.closeAddCharge,"refreshTable":_vm.refreshTable}}):_vm._e(),(_vm.showForm)?_c('FormModal',{attrs:{"clientData":_vm.clientData},on:{"closeModal":_vm.closeFormModal,"refresh":_vm.refreshTable}}):_vm._e(),(_vm.showBankInfo)?_c('BankInfoModal',{attrs:{"rowData":_vm.clientData,"inSpecialist":_vm.inSpecialist},on:{"close":_vm.closeBankInfo,"send-data":_vm.senDataBankInfo}}):_vm._e(),(_vm.showModalFiles)?_c('ModalUploadFiles',{attrs:{"potencialAppId":_vm.clientData.id,"program":_vm.program_id,"tabName":_vm.tabName},on:{"close":_vm.closeModalFiles}}):_vm._e(),(_vm.showModalBinnacle)?_c('ModalBinnaclePotential',{attrs:{"clientInformation":_vm.clientData},on:{"close":_vm.closeModalBinnacle,"refresh":_vm.refreshTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }