<template>
  <b-container class="p-0" fluid>
    <filter-slot
      class="border-3 border-table-radius p-0 m-0"
      :filter="visibleFilters"
      :filter-principal="filterSlot.filterPrincipal"
      :total-rows="filterSlot.paginate.totalRows"
      :paginate="filterSlot.paginate"
      :start-page="filterSlot.paginate.startPage"
      :to-page="filterSlot.paginate.toPage"
      @reload="$refs['potencialAppointmentTable'].refresh()"
      @reset-all-filters="resetAllFilters"
    >
      <template #table>
        <b-table
          ref="potencialAppointmentTable"
          class="m-0 p-0"
          :items="myProvider"
          :fields="visibleFields"
          :current-page.sync="filterSlot.paginate.currentPage"
          :per-page.sync="filterSlot.paginate.perPage"
          :busy.sync="filterSlot.isBusy"
          sticky-header="500px"
          show-empty
          responsive
          small
          :foot-clone="false"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(client)="{ item }">
            <span class="d-flex align-items-center" style="gap: 5px">
              <IconStatusAccount :status="item.status_account_id" fromBc />
              <router-link
                target="_blank"
                :to="{
                  name: clientDashboardRouteName,
                  params: {
                    idClient: `${item.account_id}`,
                  },
                }"
              >
                {{ item.client_name }}
              </router-link>
            </span>
            <div class="d-flex align-items-center">
              <span class="d-block" style="margin-left: 18px">
                {{ item.account }}
              </span>
            </div>
            <b-badge
              v-if="item.program_id === 3"
              class="font10 ml-1 text-uppercase"
              :variant="
                item.client_type_id == 2 ? 'light-primary' : 'light-warning'
              "
            >
              {{ item.client_type_id == 2 ? "Digital" : "Regular" }}
            </b-badge>
          </template>

          <template #cell(name_specialist)="{ item }">
            <div class="d-flex flex-column">
              <span>{{ item.name_specialist }}</span>
              <span>{{ item.created_at | myGlobalWithHour }}</span>
            </div>
          </template>

          <template #cell(responsible)="data">
            <center>
              <b-badge
                id="span_status"
                :class="statusColorBackground(data.value)"
                pill
              >
                <span v-if="data.item.responsible == 1 && moduleId == 25"
                  >IN AGENT</span
                >
                <span v-else> {{ data.item.responsible }} </span>
              </b-badge>
            </center>
          </template>

          <template #cell(form)="{ item }">
            <feather-icon
              icon="ClipboardIcon"
              size="20"
              class="cursor-pointer"
              v-b-tooltip.hover.left="'Form Potential'"
              :class="[colorIconForm(item)]"
              @click="openFormModal(item)"
            />
          </template>
          <template #cell(status_result)="{ item }">
            <ChangeStatusP
              v-if="availableForm(item)"
              :item="item"
              :moduleId="moduleId"
              :options="table.optionsStatusResult"
              :inSpecialist="inSpecialist"
              @send="updateStatus"
              nameProp="status_result"
              option="StatusResult"
              @refresh="refreshTable"
            />
          </template>
          <template #cell(bank_info)="{ item }">
            <feather-icon
              v-if="availableForm(item)"
              icon="CreditCardIcon"
              size="20"
              :class="statusColorBankInfo(item)"
              @click="openBankInfo(item)"
            />
          </template>
          <template #cell(minimum_amount)="{ item }">
            $ {{ parseFloat(item.minimum_price).toFixed(2) }}
          </template>
          <template #cell(status_paid)="{ item }">
            <div
              v-if="item.status_paid === 'PENDING' && availableForm(item)"
              class="text-warning"
            >
              VERIFYING
            </div>
            <template v-else>
              <ChangeStatusP
                v-if="
                  item.status_result == 'APPROVED' &&
                  item.bank_info &&
                  item.bank_info.status_done == 1 &&
                  availableForm(item)
                "
                :item="item"
                :moduleId="moduleId"
                :options="table.optionsStatusPaid"
                :inSpecialist="inSpecialist"
                @send="updateStatus"
                nameProp="status_paid"
                option="StatusPaid"
              />
            </template>
          </template>
          <template #cell(amount)="{ item }">
            <div
              v-if="item.amount != null && item.bank_info != null"
              class="d-flex flex-column"
            >
              <span title="Fee">{{ item.amount | formatMoney }}</span>
            </div>
          </template>
          <template #cell(files)="{ item }">
            <feather-icon
              class="cursor-pointer"
              icon="PaperclipIcon"
              size="20"
              :class="Number(item.count_file) > 0 ? 'text-primary' : ''"
              @click="openModalFiles(item)"
            />
          </template>
          <template #cell(binnacle)="{ item }">
            <tabler-icon
              :badge="totalBinnacle(item)"
              badge-classes="badge-important"
              icon="BrandMessengerIcon"
              size="25"
              class="text-secondary"
            />
          </template>
          <template #cell(tracking)="{ item }">
            <div class="d-flex flex-row justify-content-around px-1">
              <feather-icon
                class="cursor-pointer text-info"
                icon="ListIcon"
                size="20"
                @click="openModalTracking(item)"
              />
            </div>
          </template>

          <template #cell(actions)="{ item }">
            <feather-icon
              v-if="
                inSpecialist &&
                item.status == 'PENDING' &&
                item.status_paid == null
              "
              class="cursor-pointer text-danger"
              icon="TrashIcon"
              size="20"
              @click="removeRegister(item)"
            />
          </template>

          <template v-if="tabName == 'COMPLETED'" #custom-foot>
            <b-tr class="bg-dark fixed-footer">
              <b-th :colspan="indexAmount" />
              <b-th>
                <div class="d-flex flex-column" style="font-size: 16px">
                  <b-badge class="rounded-pill mb-1 ml-auto" variant="info"
                    >Sub Total: $ {{ subtotalAmount | currency }}</b-badge
                  >
                  <b-badge class="rounded-pill ml-auto" variant="info"
                    >Total: $ {{ totalAmount | currency }}</b-badge
                  >
                </div>
              </b-th>
              <b-th colspan="11"></b-th>
            </b-tr>
          </template>
        </b-table>
        <br />
      </template>
    </filter-slot>
    <ModalTracking
      :data="activeModalTracking"
      v-if="activeModalTracking"
      @close="closeModalTracking"
    />
    <AddCharge
      v-if="showAddCharge"
      :action="1"
      @close="closeAddCharge"
      :data="clientData"
      @refreshTable="refreshTable"
      :allPaymentMethods="false"
      sendFrom="potencial_appointments"
    />
    <FormModal
      v-if="showForm"
      @closeModal="closeFormModal"
      :clientData="clientData"
      @refresh="refreshTable"
    />
    <BankInfoModal
      v-if="showBankInfo"
      @close="closeBankInfo"
      :rowData="clientData"
      @send-data="senDataBankInfo"
      :inSpecialist="inSpecialist"
    />
    <ModalUploadFiles
      :potencialAppId="clientData.id"
      v-if="showModalFiles"
      :program="program_id"
      :tabName="tabName"
      @close="closeModalFiles"
    />
    <ModalBinnaclePotential
      :clientInformation="clientData"
      v-if="showModalBinnacle"
      @close="closeModalBinnacle"
      @refresh="refreshTable"
    />
  </b-container>
</template>
<script>
import Fields from "@/views/commons/components/potencial-appointment/data/fields";
import Filters from "@/views/commons/components/potencial-appointment/data/filters";
import IconStatusAccount from "@/views/commons/components/zero-payment/views/IconStatusAccount.vue";
import PotentialAppService from "@/views/commons/components/potencial-appointment/services/potencial-appointments.service";
import { mapGetters, mapMutations, mapActions } from "vuex";
import ModalTracking from "@/views/commons/components/potencial-appointment/components/modals/ModalTracking.vue";
import ChangeStatusP from "@/views/commons/financial-approval/ChangeStatusP.vue";
import AddCharge from "@/views/commons/components/applications/views/components/others/AddCharge.vue";
import FormModal from "@/views/commons/components/potencial-appointment/components/modals/FormModalP.vue";
import ModalUploadFiles from "@/views/commons/components/potencial-appointment/components/modals/ModalUploadFiles.vue";
import ModalBinnaclePotential from "@/views/commons/components/potencial-appointment/components/modals/ModalBinnaclePotential.vue";
import TotalComponent from "@/views/commons/components/applications/views/components/others/TotalComponent.vue";
import BankInfoModal from "@/views/commons/financial-approval/BankInfoModal.vue";
export default {
  name: "TablePotencial",
  props: { program_id: { type: Number, required: true } },
  components: {
    IconStatusAccount,
    ModalTracking,
    AddCharge,
    FormModal,
    ModalUploadFiles,
    ModalBinnaclePotential,
    ChangeStatusP,
    TotalComponent,
    BankInfoModal,
  },
  data() {
    return {
      showModalBinnacle: false,
      showModalFiles: false,
      isOtherServices: false,
      showAddCharge: false,
      showForm: false,
      showBankInfo: false,
      clientData: null,
      activeModalTracking: null,
      filterSlot: {
        paginate: {
          currentPage: 1,
          perPage: 25,
          startPage: 0,
          startPageUrl: "",
          toPage: 0,
          nextPage: 0,
          nextPageUrl: "",
          prevPageUrl: "",
          endPage: 0,
          endPageUrl: "",
          totalRows: 0,
        },
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Client...",
          model: "",
        },
      },
      table: {
        fields: Fields,
        isBusy: false,
        optionsStatus: ["", "ACCEPTED", "REJECTED"],
        optionsStatusResult: ["IN PROCESS","APPROVED", "DENIED"],
        optionsStatusPaid: ["", "YES", "NO"],
      },
      filters: Filters,
      items: [],
      routesModules: {
        5: "debt-solution-potencial-appointment",
        6: "credit-experts-potencial-appointment",
        7: "boost-credit-potencial-appointment",
        20: "connection-potencial-appointment",
        22: "customerservice-potencial-appointment",
        29: "potencial-financial-approval",
      },
    };
  },
  computed: {
    ...mapGetters({
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      this.modifyVisibility("actions", this.tabName == "IN PROCESS");
      this.modifyVisibility("status_result", this.tabName != "COMPLETED");
      this.modifyVisibility("responsible", this.tabName == "IN PROCESS");
      this.modifyVisibility("status_paid", this.tabName != "COMPLETED");
      return this.table.fields.filter((field) => field.visible);
    },
    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    tabName() {
      return this.$route.meta.tabName;
    },
    inSpecialist() {
      const modules = [11, 25, 28, 29, 30];
      return modules.includes(this.moduleId);
    },
    visibleFilters() {
      this.filters[2].visible = false;
      if (this.program_id === 7) {
        this.filters[2].visible = true;
      }
      return this.filters.filter((x) => x.visible);
    },
    totalAmount() {
      let total = this.items.length > 0 ? this.items[0].total_ammount : 0;
      return parseFloat(total);
    },
    subtotalAmount() {
      const subtotal = this.items.reduce(
        (total, item) => Number(total) + Number(item.amount),
        0
      );
      return parseFloat(subtotal);
    },
    indexAmount() {
      return this.table.fields
        .filter((field) => field.visible)
        .findIndex((item) => item.key == "amount");
    },
  },
  watch: {
    tabName: {
      handler(value) {
        if (value == "IN PROCESS") {
          this.hideColumnGrid("amount", false);
        } else {
          this.hideColumnGrid("amount", true);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    statusColorBackground(status) {
      switch (status) {
        case "IN ADVISOR":
          return ["bg-light-warning"];
        case "IN SPECIALIST":
          return ["bg-light-primary"];
        case 3:
          return ["bg-light-success"];
        default:
          return "bg-secondary text-light";
      }
    },
    ...mapActions({
      A_GET_PENDING_POTENTIAL: "PotentialStore/A_GET_PENDING_POTENTIAL",
    }),
    ...mapMutations({
      M_PENDING_POTENCIAL: "PotentialStore/M_PENDING_POTENCIAL",
    }),
    async removeRegister(item) {
      const params = {
        id: item.id,
        user_id: this.currentUser.user_id,
      };
      try {
        const confirm = await this.showConfirmSwal();
        if (!confirm.isConfirmed) return;

        this.addPreloader();
        const data = await PotentialAppService.removeRegister(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Removed successfully"
          );
          this.$refs.potencialAppointmentTable.refresh();
          await this.refreshCountInSidebar();
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "CXIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    hideColumnGrid(key, value) {
      let columnExists = this.table.fields.find((x) => x.key == key);
      if (columnExists) {
        columnExists.visible = value;
      }
    },
    getValueFilter(key) {
      let data = this.filters.find((x) => x.key === key);
      if (data) return data.model;
      return null;
    },
    async myProvider(ctx) {
      try {
        const { currentPage, perPage } = ctx;
        const data = await PotentialAppService.search({
          nSearch: this.filterSlot.filterPrincipal.model,
          nPage: currentPage,
          nPerPage: perPage,
          nModuleId: this.moduleId,
          nTab: this.tabName,
          nDateFrom: this.getValueFilter("date_from"),
          nDateTo: this.getValueFilter("date_to"),
          nStatus: this.getValueFilter("status"),
          nResult: this.getValueFilter("result"),
          nPaid: this.getValueFilter("paid"),
          nProgramId: this.getValueFilter("program"),
          nType: this.getValueFilter("type"),
        });
        this.filterSlot.paginate.startPage = data.from;
        this.filterSlot.paginate.currentPage = data.current_page;
        this.filterSlot.paginate.perPage = data.per_page;
        this.filterSlot.paginate.totalRows = data.total;
        this.filterSlot.paginate.toPage = data.to;
        this.items = data.data;

        // this.refreshCountInSidebar();
        return data.data;
      } catch (ex) {}
      return [];
    },
    modifyVisibility(nameKey, isVisible) {
      let keyField = this.table.fields.findIndex((item) => item.key == nameKey);
      if (keyField != -1) {
        this.table.fields[keyField].visible = isVisible;
      }
    },
    openModalTracking(item) {
      this.activeModalTracking = item;
    },
    openModalFiles(item) {
      this.clientData = item;
      this.showModalFiles = true;
    },
    closeModalTracking() {
      this.activeModalTracking = null;
    },
    closeModalFiles(hasFile) {
      this.clientData = null;
      this.showModalFiles = false;
      if (hasFile) {
        this.refreshTable();
      }
    },
    openAddCharge(item) {
      this.showAddCharge = true;
      this.clientData = {
        ...item,
        client_account: item.account,
        suggets_charge: item.bank_info.loan_ammount,
        type_description: item.type_potential,
      };
    },
    closeAddCharge() {
      this.showAddCharge = false;
      this.clientData = null;
    },
    async openFormModal(item) {
      try {
        let paf = item.potential_appointment_forms;
        this.showForm = true;
        this.clientData = {
          id: item.id,
          account_id: item.account_id,
          status_done: paf ? paf.status_done : "SAVE",
        };
      } catch (ex) {
        console.log(ex);
      }
    },
    closeFormModal() {
      this.showForm = false;
      this.clientData = null;
    },
    async openBankInfo(item) {
      try {
        if (item.status_result === "APPROVED") {
          this.addPreloader();
          const data = await PotentialAppService.getDataBank({
            n_pa_id: item.id,
          });
          this.showBankInfo = true;
          this.clientData = data;
          this.removePreloader();
        }
      } catch (ex) {
        this.removePreloader();
      }
    },
    closeBankInfo() {
      this.showBankInfo = false;
    },
    statusColorBankInfo(item) {
      let color;
      if (item.bank_info) {
        const { status_done } = item.bank_info;
        let status_result = status_done
          ? item.status_result
          : this.validStatusResult(item);
        switch (status_result) {
          case "PENDING":
            color = "text-warning";
            break;
          case "APPROVED":
            color = "text-success cursor-pointer";
            break;
          case "DENIED":
            color = "text-danger";
            break;
          case "PENDING-BANK":
            color = "text-warning cursor-pointer";
            break;
          default:
            color = "text-secondary";
            break;
        }
      } else {
        color = "text-secondary";
      }
      return color;
      // item.status_result === 'APPROVED' ? 'text-success' : 'text-secondary'
    },
    validStatusResult(item) {
      return item.status_result === "PENDING" ||
        item.status_result === "DENIED" ||
        item.status_result == null
        ? "DEFAULT"
        : "PENDING-BANK";
    },
    statusColorPaid(item) {
      try {
        const { bank_info, status_paid } = item;
        let textColor;
        if (!bank_info || Number(bank_info.status_done) === 0) {
          return "text-secondary cursor-block";
        } else {
          switch (status_paid) {
            case "PENDING":
              textColor = "text-warning cursor-pointer";
              break;
            case "YES":
              textColor = "text-success cursor-pointer";
              break;
            case "NO":
              textColor = "text-danger cursor-block";
              break;
            default:
              textColor = "text-secondary cursor-block";
              break;
          }
        }
        return textColor;
      } catch (ex) {
        console.log(ex);
      }
    },
    refreshTable() {
      this.$refs["potencialAppointmentTable"].refresh();
      this.refreshCountInSidebar();
    },
    async openBinnacle(item) {
      try {
        this.showModalBinnacle = true;
        this.clientData = {
          name_client: item.client_name,
          module: item.appointment_obj.module,
          created_at: item.created_at_client,
          name_advisor: item.name_advisor,
          name_charge: item.type_description,
          id: item.id,
          section: "appointment",
        };
      } catch (ex) {
        this.clientData = null;
        console.log(ex);
      }
    },
    closeModalBinnacle() {
      this.showModalBinnacle = false;
      this.clientData = null;
    },
    async updateStatus(data) {
      let _data = {
        n_potencial_appointment_id: data.n_id,
        n_module_id: data.n_module_id,
        n_user_id: data.n_user_id,
        n_action: data.n_action,
        n_option: data.n_option,
        n_comment: data.n_comment,
      };
      try {
        this.addPreloader();
        const { n_option, n_action } = data;
        if (n_option === "StatusPaid") {
          if (n_action === "NO" || n_action === "PENDING") {
            await PotentialAppService.updateStatus(_data);
            this.refreshTable();
          } else {
            this.openAddCharge(data);
          }
        } else {
          await PotentialAppService.updateStatus(_data);
          this.refreshTable();
        }
      } catch (ex) {
        this.showErrorSwal(ex);
      } finally {
        this.removePreloader();
      }
    },
    totalBinnacle(item) {
      let total = 0;
      try {
        const { without_seeing, without_seeing_files } = item;
        const totalWithoutSeeing = without_seeing
          ? without_seeing.filter((x) => x.user_id != this.currentUser.user_id)
              .length
          : 0;
        const totalWithoutSeeingFiles = without_seeing_files
          ? without_seeing_files.filter(
              (x) => x.user_id != this.currentUser.user_id
            ).length
          : 0;
        total = totalWithoutSeeing + totalWithoutSeeingFiles;
      } catch (ex) {
        total = 0;
      }
      return total;
    },
    async senDataBankInfo(value) {
      try {
        this.addPreloader();
        const data = await PotentialAppService.updateDataBank(value);

        this.refreshTable();
        this.closeBankInfo();
      } catch (ex) {
        console.error(ex)
      } finally { 
        this.removePreloader();
      }
    },
    async refreshCountInSidebar() {
      await this.A_GET_PENDING_POTENTIAL();
    },
    availableForm(item) {
      const paf = item.potential_appointment_forms;
      return paf ? paf.status_done === "SEND" : false;
    },
    colorIconForm(item) {
      const paf = item.potential_appointment_forms;
      const status_done = paf ? paf.status_done : "";
      let color;
      switch (status_done) {
        case "SAVE": {
          color = "text-warning";
          break;
        }
        case "SEND": {
          color = "text-success";
          break;
        }
        default:
          color = "text-default";
          break;
      }
      return color;
    },
    resetAllFilters() {
      this.visibleFilters.forEach((filter) => {
        filter.model = null;
      });
      this.filterSlot.filterPrincipal.model = null;
      this.$refs.potencialAppointmentTable.refresh();
    },
  },
};
</script>
<style scoped>
.cursor-block {
  cursor: not-allowed;
}
@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.oscillate {
  animation-name: oscillate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
</style>
