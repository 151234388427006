import { amgApi } from "@/service/axios";

class BinnaclePotentialService {
    constructor() {
        this.url = '/potential-appointment/binnacle';
    }
    async getCommentsBinnacle(params) {
        const { data } = await amgApi.post(`${this.url}/index`, params);
        return data;
    }
    async getMessageStatus(params) {
        const { data } = await amgApi.post(`${this.url}/get-message-status`, params);
        return data;
    }
    async insertMessageBinnacle(params) {
        const { data } = await amgApi.post(`${this.url}/store`, params);
        return data;
    }
    async markAsSeen(params) {
        const { data } = await amgApi.post(`${this.url}/mark-as-seen`, params);
        return data;
    }
    async insertFileBinnacle(params) {
        const { data } = await amgApi.post(`${this.url}/files/store`, params,{
            headers:{
                'Content-Type':"multipart/form-data"
            }
        });
        return data;
    }
}
export default new BinnaclePotentialService();